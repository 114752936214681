import Cookies from 'js-cookie'
// import { getBrowerType } from '@/utils/system/systemUtils'

// 通用函数：检查Cookie值
const checkCookieValue = (expectedValue) => {
  const fromCookie = Cookies.get('from')
  return fromCookie !== undefined && fromCookie !== null && fromCookie !== '' && fromCookie === expectedValue
}

/**
 * @returns {boolean} true 微信小程序
 */
export const isWxminiAigc = () => checkCookieValue('wx') // 之前代码 Cookies.get('from') === 'wx'

/**
 * @returns {boolean} true 联想小程序
 */
export const isFromLenovo = () => checkCookieValue('lenovo') // 之前代码 Cookies.get('from') && Cookies.get('from') === 'lenovo'
